import { ref, Ref } from 'vue';
import {
    getSearch, ListHeaderItem, RequestData, SearchKey
} from '@/components/common/list';
import router, { propertyBaseRouter, propertyOldCommunitySubRouter, propertySubRouter } from '@/router';
import { community } from '@/data';
const breadHeaders = [{ label: WordList.DarshbordTmp }];

const headers: Array<ListHeaderItem> = [
    {
        label: WordList.TmpKeyListTanleKey,
        name: 'Code'
    }, {
        label: WordList.OrderDevice,
        name: 'Location'
    }, {
        label: WordList.TabelPersonUserInHtmlName,
        name: 'Description'
    }, {
        label: WordList.EachDoorCounts,
        name: 'Count'
    }, {
        label: WordList.ProperAllTextRepeats,
        name: 'Repeats'
    }, {
        label: WordList.ProperAllTextCreatedBy,
        name: 'Creator'
    }, {
        label: WordList.TabelExpretimeBoxSpecificTime,
        name: 'EndTime'
    }, {
        label: WordList.TmpKeyManageListTanleQRCode,
        name: 'QrCodeUrl',
        type: 'customize'
    }
];

// search列表
const searchKeyList: SearchKey = [
    {
        name: 'Description',
        label: WordList.TabelPersonUserInHtmlName
    }
];

const initList = () => {
    // list请求借口及请求参数
    const requestData: Ref<RequestData> = ref({
        url: `v3/web/${community.ProjectType.value}/tempKey/getList`,
        param: getSearch({
            searchValue: '',
            searchKey: 'Description'
        })
    });

    // list搜索查询处理
    const updateList = ref(0);
    const saveParamInPath = ref(false);
    const searchList = () => {
        updateList.value += 1;
        saveParamInPath.value = true;
    };

    const add = () => {
        router.push(`/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldTempKeyNew}`);
    };

    const info = (val: {ID: string}) => {
        router.push(`/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldTempKeyInfo}?ID=${val.ID}`);
    };
    return {
        breadHeaders,
        add,
        requestData,
        searchKeyList,
        searchList,
        updateList,
        saveParamInPath,
        headers,
        info
    };
};
export default initList;
