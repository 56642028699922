
import { defineComponent, ref } from 'vue';
import initList from '@/views/pm/old-community/temp-key/init-list';
import PropertyBreadCrumb from '@/components/view/pm/bread-crumb';
import { listPagination } from '@/components/common/list';
import AddButton from '@/components/common/add-button/index.vue';
import { limitErrorImg } from '@/methods/basicFun';
import EnlargeImage from '@/components/common/enlarge-image/index.vue';
import { community } from '@/data';

export default defineComponent({
    components: {
        PropertyBreadCrumb,
        listPagination,
        AddButton,
        EnlargeImage
    },
    setup() {
        const imgItem = ref<Array<any>>([]);
        const setAllImg = (el: any) => {
            if (el) {
                limitErrorImg(el);
                imgItem.value.push(el);
            }
        };

        const {
            breadHeaders,
            requestData,
            searchKeyList,
            searchList,
            updateList,
            saveParamInPath,
            headers,
            add,
            info
        } = initList();

        // 图片放大的组件
        const isShowEnlargeImage = ref(false);
        const picAddress = ref('');
        const showEnlargeImage = (address: string) => {
            picAddress.value = address;
            isShowEnlargeImage.value = true;
        };

        return {
            breadHeaders,
            requestData,
            searchKeyList,
            searchList,
            updateList,
            saveParamInPath,
            headers,
            add,
            info,
            showEnlargeImage,
            setAllImg,
            isShowEnlargeImage,
            picAddress,
            community
        };
    }
});
